<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card>
          <h4 class="card-title">
            Instruções
          </h4>
          <p>As mensagens serão migradas do usuário Origem para o usuário Destino.</p>
          <p>Aguarde o término do processamento sem recarregar a página. Até 500 conversas deve ser migrado em menos de 10 segundos.</p>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="5">
              <b-form-group
                label="Origem (ID do usuário)"
                label-for="userIdFrom"
              >
                <b-form-input
                  id="userIdFrom"
                  v-model="filtersValues.userIdFrom"
                  :disabled="isLoading"
                  @change="changed"
                />
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group
                label="Destino (ID do usuário)"
                label-for="userIdTo"
              >
                <b-form-input
                  id="userIdTo"
                  v-model="filtersValues.userIdTo"
                  :disabled="isLoading"
                  @change="changed"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="validate.user_from">
            <b-col cols="5">
              <p>
                <span>Nome: {{ validate.user_from.name }}</span> <br>
                <span>Empresa: {{ validate.user_from.company_name }}</span> <br>
                <span>Tipo: {{ $t(`users.columns.type.${validate.user_from.type}`) }}</span> <br>
                <span>Mensagens: {{ validate.user_from.messages }}</span>
              </p>
            </b-col>

            <b-col cols="5">
              <p>
                <span>Nome: {{ validate.user_to.name }}</span> <br>
                <span>Empresa: {{ validate.user_to.company_name }}</span> <br>
                <span>Tipo: {{ $t(`users.columns.type.${validate.user_from.type}`) }}</span> <br>
                <span>Mensagens: {{ validate.user_to.messages }}</span>
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="10"
              class="form-buttons text-right"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  v-if="!isValidated"
                  id="btn-validate"
                  :disabled="isLoading"
                  variant="primary"
                  @click="validateUsers()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Validar usuários
                </b-button>

                <b-button
                  v-if="isValidated"
                  id="btn-migrate"
                  :disabled="!isValidated || isLoading"
                  variant="primary"
                  @click="migrateMessages()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Migrar Mensagens
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="isMigrated">
            <b-col cols="12">
              <p>
                <span>Total de mensagens migradas: {{ results.migrated }}</span> <br>
                <span v-if="results.errors.length">Erros:</span><br>
                <span
                  v-for="(value, i) in results.errors"
                  :key="`error${i}`"
                >
                  {{ value }}<br>
                </span>
              </p>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import ConnectionService from '@/services/connectionService'
import { formatDateTimeDbToView } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BSpinner,
  BButton,
  BBreadcrumb,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import requestErrorMessage from '@/mixins/requestErrorMessage'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BSpinner,
    BButton,
    BBreadcrumb,
  },

  mixins: [requestErrorMessage],

  data() {
    return {
      userService: null,
      isLoading: false,
      isValidated: false,
      isMigrated: false,
      validate: {
        user_from: null,
        user_to: null,
      },
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Migrar Histórico',
          active: true,
        },
      ],

      filtersInitialValues: {},
      filtersValues: {
        userIdFrom: null,
        userIdTo: null,
        type: 'all',
        status: 'all',
        sortBy: 'id',
        sortDesc: true,
      },

      pages: 1,
      currentPage: 1,
      results: {
        migrated: 0,
        errors: [],
      },
      result_fields: [],
      formatDateTimeDbToView,
    }
  },

  watch: {
    currentPage() {
      this.getUsers()
    },
  },

  created() {
    this.userService = new UserService()
    this.connectionService = new ConnectionService()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'id', sortable: false, thStyle: 'width: 120px' },
      { key: 'type', sortable: false, thStyle: 'width: 170px' },
      { key: 'name', sortable: false },
      { key: 'company_name', sortable: false },
      { key: 'allEmails', sortable: false },
      { key: 'allPhones', sortable: false, thStyle: 'width: 180px' },
      { key: 'status', sortable: false },
      { key: 'actions', sortable: false, thStyle: 'width: 120px' },
    ]
  },

  methods: {
    handleCtrlEnter(e) {
      if (e.ctrlKey) {
        this.getUsers()
      }
    },

    changed() {
      this.isValidated = false
    },

    validateUsers() {
      this.validate = {
        user_from: null,
        user_to: null,
      }
      this.isValidated = false
      this.isLoading = true
      this.isMigrated = false
      this.connectionService.validateMigrate(this.filtersValues.userIdFrom, this.filtersValues.userIdTo).then(response => {
        if (response.status === 200) {
          this.validate = response.data.data.validate
          this.isValidated = true
          this.isLoading = false
        }
      }).catch(error => {
        this.isValidated = false
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: this.getResponseErrorMessage(error.response.data),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    migrateMessages() {
      this.$swal({
        title: 'Migrar mensagens?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, migrar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.isMigrated = false
          this.connectionService.migrate(this.filtersValues.userIdFrom, this.filtersValues.userIdTo).then(response => {
            if (response.status === 200) {
              this.results = response.data.data.migrate
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Mensagens migradas com sucesso!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
            this.isMigrated = true
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: this.getResponseErrorMessage(error.response.data),
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
